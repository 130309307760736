import React, { useState } from 'react';

// Components
import WorldsList from '../WorldsList'
import Timeline from '../Timeline'
import Tabs from '../components/Tabs'
import Icon from '../components/Icon'
import Tag from '../components/Tag'

// import worlds from './data/worlds.json'
// import { images } from './data/data.js'
// import { uuids, tags } from './data/data.json'

/** Home page */
function Home({
    filters, setFilters,
    filtersOpen, setFiltersOpen,
    sort, setSort,
    homeTab, setHomeTab,

    toggleFilter, toggleFilterMenu
}) {
    return (
        <>
        {/* <!-- Nav --> */}
        <header className="main_header container">
            <h1>Minecraft Worlds</h1>
        </header>

        <nav className="main_nav container">
            <div className="inner flex media_flex">
                <Tabs tabs={['Worlds', 'Timeline (WIP)']} active={homeTab} setActive={setHomeTab} />
                {homeTab === 'Worlds' ?
                    <div className="buttons flex">
                        {/* Sort */}
                        <label htmlFor="sort">
                            <p>Sort:</p>
                            <select name="sort" id="sort" value={sort} onChange={event => setSort(event.target.value)}>
                                <option value="relevant">Relevant</option>
                                <option value="chronological">Chronological</option>
                                <option value="screenshots">Screenshots</option>
                            </select>
                        </label>

                        {/* Filter */}
                        <label htmlFor="open_filters">
                            <p>Filter:</p>
                            <button onClick={toggleFilterMenu} className={`small ${filtersOpen?"active":null}`}>
                                <p><Icon icon="filter" /></p>
                                <span/>
                            </button>
                        </label>
                    </div>
                    :
                    null
                }
            </div>

            {homeTab === 'Worlds' ?
                <div id="filters" className={filtersOpen ? "open":""}>
                    <h4>Filters</h4>
                    <p className="tags">
                        <ToggleTag name="Downloadable" filters={filters} toggleFilter={toggleFilter} />
                        <ToggleTag name="Statistics" filters={filters} toggleFilter={toggleFilter} />
                        <ToggleTag name="Vanilla" filters={filters} toggleFilter={toggleFilter} />
                        <ToggleTag name="Modded" filters={filters} toggleFilter={toggleFilter} />
                        <ToggleTag name="Singleplayer" filters={filters} toggleFilter={toggleFilter} />
                        <ToggleTag name="Multiplayer" filters={filters} toggleFilter={toggleFilter} />
                        <ToggleTag name="Survival" filters={filters} toggleFilter={toggleFilter} />
                        <ToggleTag name="Creative" filters={filters} toggleFilter={toggleFilter} />
                    </p>
                </div>
                :
                null
            }
        </nav>

        {/* <!-- List --> */}
        {homeTab === 'Worlds' ?
            <WorldsList sort={sort} filters={filters}/> :
            <Timeline/>
        }

        {/* <!-- Footer --> */}
        <footer id="footer">
            <div className="inner container alt_text">
                <p>
                    Minecraft skin API provided by <a href="https://mc-heads.net" target="_blank" rel="noopener noreferrer">MCHeads</a>
                </p>
                <br/>
                <p>
                    © notkal.com
                </p>
            </div>
        </footer>
        </>
    );
}

export default Home;


function ToggleTag({ name, filters, toggleFilter }) {
    return (
        <Tag
            name={name} after={<Icon icon="add"/>} toggle={true}
            onClick={() => toggleFilter(name)} className={filters[name] ? "active":undefined} />
    )
}
